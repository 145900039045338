/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2"
  }, _provideComponents(), props.components), {MapInfo, ThreeColCta, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!MapInfo) _missingMdxReference("MapInfo", true);
  if (!ThreeColCta) _missingMdxReference("ThreeColCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "take-care-of-your-ears-while-hunting",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#take-care-of-your-ears-while-hunting",
    "aria-label": "take care of your ears while hunting permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Take care of your ears while hunting!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/birds-hero.png",
    alt: "Birds flying in the Sky",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you love hunting, you know that having a keen ear is one of the hunter’s most important assets. And you also know that it’s essential to protect your hearing against sudden gunshots or other hunting-related noises. Studies have found that men over the age of 48 who hunt regularly are statistically more likely to experience ", React.createElement(_components.a, {
    href: "/hearing-loss/high-frequency",
    className: "c-md-a"
  }, "high-frequency hearing loss"), " – the type associated with damage from sudden loud explosions. At the same time, the evidence suggests that many hunters fail to take precautions to avoid serious hearing problems. So, how can you protect your ears while out stalking your prey? Here is how to keep your ears in great shape while out in the country with your buddies."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-protect-your-ears-when-out-hunting",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-protect-your-ears-when-out-hunting",
    "aria-label": "how to protect your ears when out hunting permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to protect your ears when out hunting?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The first thing to do if you are worried about your hearing is to understand the risks. Even a single loud gunshot can permanently damage your hearing. It doesn’t have to be a couple of feet away, either. Shots from twenty to thirty meters away can also degrade your auditory abilities, particularly if heard repeatedly and over a long period. Generally, experts report that noises of 85 decibels or more can cause permanent hearing issues, and gunshots are usually in the range of 120 to 130 decibels – more than enough to scramble your hearing for good."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So, store that warning in your mind before you head out for an expedition: there’s a risk, and you need to take precautions. But what are the specific precautions you need to take? The most important thing to do is to wear protective gear that muffles sound and vibrations from gunshots. This is easily the most important fix you can make that will prevent hearing damage. Fortunately, there are a number of tools hunters can use to achieve that outcome."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "different-types-of-ear-protection-for-hunters-to-consider",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#different-types-of-ear-protection-for-hunters-to-consider",
    "aria-label": "different types of ear protection for hunters to consider permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Different types of ear protection for hunters to consider"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The most common form of ear protection for hunters is earplugs. Generally made of foam and ridged to ensure they remain securely in place, specialist hunting earplugs are available from brands like Sportear or Auritech. These earplugs have been designed with firearms users in mind. When you choose a pair, you’ll come across a metric called the “noise reduction rating.”"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To find out how much your plugs actually reduce gunshot noise, take this number, subtract seven, then divide it by two. However, there’s a catch: earplugs cannot reduce the impact of vibrations from gunshots, and they are a blunt instrument, blocking out all ambient sound, which can be a big problem for serious hunters. This means that many hunters avoid earplugs and prefer ear muffs instead. Brands like Peltor or Honeywell manufacture effective hunting headsets that muffle the sound and vibrations of gunshots. At the same time, they actually amplify other noises via sophisticated detection systems, allowing hunters to remain aware of what’s going on in the brush without risking their hearing. If you intend to hunt regularly, it’s definitely worth checking out these smart ear muffs, which don’t tend to retail for much more than standard models."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, don’t order a pair just yet, though. There’s a final option that many hunters are adopting: electronic ear plugs. The great advantage of these devices (which are worn like hearing aids) is that they can be tuned to block out specific frequencies. The disadvantage is that they provide little protection against vibration, but for hunters who need to remain alert and mobile, this is often a sacrifice worth making."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-about-hunting-with-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-about-hunting-with-hearing-aids",
    "aria-label": "what about hunting with hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What about hunting with hearing aids?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you are a hearing aid user, you might assume that hunting is a thing of the past, but that doesn’t have to be the case. However, there’s no doubt that hunting poses some challenges for hearing aid users. Most importantly, rapid, loud noises like gunfire can overload the microphones on many ", React.createElement(_components.a, {
    href: "/hearing-aids/brands",
    className: "c-md-a"
  }, "hearing aid models"), ", rendering them ineffective. And hunters can also fall into the trap of assuming that their device’s noise cancellation function will protect them against gunfire, when that’s not usually the case."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Abrupt noises can be too fast for many devices to detect, delivering every single decibel to your inner ear. So, what’s the solution? A hearing aid that is adapted for hunting. As we touched upon earlier, there are electronic ear plugs that resemble hearing aids. There are also hearing aids that feature highly sensitive noise detection and cancellation that can handle gunshots."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Check out product ranges like ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/starkey",
    className: "c-md-a"
  }, "Starkey’s SoundGear collection"), " for examples of this kind of hearing aid. They won’t generally be a substitute in everyday life for your specialist device, but they can be a very handy supplement for periodic hunting trips."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-importance-of-protecting-your-ears-when-hunting",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-importance-of-protecting-your-ears-when-hunting",
    "aria-label": "the importance of protecting your ears when hunting permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The importance of protecting your ears when hunting"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hunting is one of America’s national sports, with over 13 million people enjoying the activity every year. Obviously that results in a lot of fun and pleasure, but it also has a less enjoyable consequence: plenty of exposure to extremely loud noises. All you have to do is read the biographies of some great American hunters to understand the risks."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For instance, take Elmer Keith or Nash Buckingham – both of whom were almost deafened by their passion for firearms and hunting. You don’t have to shoot day in, day out to experience the same problems. Every shot you make without protection damages the incredibly sensitive hair cells in your inner ear which detect vibrations and tell your brain what it is hearing. And when those cells are damaged, they never come back. With that in mind, take action to protect your hearing whenever you hunt."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A small investment in noise-cancelling earplugs or a specialist hunting hearing aid could avoid costly medical expenses and ", React.createElement(_components.a, {
    href: "/hearing-loss",
    className: "c-md-a"
  }, "hearing impairment further down the line"), ", and ensure that you can hunt with your friends for as long as you can."), "\n", React.createElement(MapInfo), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resources",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resources",
    "aria-label": "resources permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Resources"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Learn everything you need to know about hearing aids and hearing loss."), "\n", React.createElement(ThreeColCta, {
    ImgSrc1: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/women-with-tablet.jpg",
    ContentTitle1: "Hearing Test",
    ContentCopy1: "Check how is your hearing in less than 5 minutes.",
    Url1: "/hearing-test/",
    ImgSrc2: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-aids-behind-ear-blur.jpg",
    ContentTitle2: "Value & Price",
    ContentCopy2: "Are hearing aids worth the cost? We asked an expert.",
    Url2: "/hearing-aids/prices/",
    ImgSrc3: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/go-ax-grey-blur.jpg",
    ContentTitle3: "Hearing Aids",
    ContentCopy3: "Connect you to the world around you with our wide range of devices.",
    Url3: "/hearing-aids/"
  }), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
